import React, { useEffect, useState } from "react";
import "./Home.css";
//This code was scavenged online and with AI help to rewrite it
const ConsoleText = ({ words, colors }) => {
    const [currentText, setCurrentText] = useState("");
    const [currentColor, setCurrentColor] = useState(colors[0]);
    const [isUnderscoreVisible, setIsUnderscoreVisible] = useState(true);

    useEffect(() => {
        let wordIndex = 0;
        let letterIndex = 0;
        let isAdding = true;

        const updateText = () => {
            setCurrentText(words[wordIndex].substring(0, letterIndex));
            if (isAdding) {
                if (letterIndex === words[wordIndex].length) {
                    isAdding = false;
                    setTimeout(updateText, 1000);
                } else {
                    letterIndex++;
                }
            } else {
                if (letterIndex === 0) {
                    isAdding = true;
                    wordIndex = (wordIndex + 1) % words.length;
                    setCurrentColor(colors[wordIndex % colors.length]);
                } else {
                    letterIndex--;
                }
            }
        };

        const textInterval = setInterval(updateText, 250); //This changes how fast the text types

        const underscoreInterval = setInterval(() => {
            setIsUnderscoreVisible((v) => !v);
        }, 400);

        return () => {
            clearInterval(textInterval);
            clearInterval(underscoreInterval);
        };
    }, [words, colors]);

    return (
        <div className="console-container" style={{ color: currentColor }}>
            {currentText}
            <span
                className={`console-underscore ${
                    isUnderscoreVisible ? "" : "hidden"
                }`}
            >
                &#95;
            </span>
        </div>
    );
};

export default ConsoleText;
