import React, { useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";

function NewContact() {
    const [state, handleSubmit] = useForm("xzzpdkjl");

    // Use useEffect to trigger the alert after form submission succeeds
    useEffect(() => {
        if (state.succeeded) {
            alert(
                "Thank you for submitting a response, I will get back to you soon!"
            );
        }
    }, [state.succeeded]);

    if (state.succeeded) {
        // Optionally, you can still return an empty div or null to hide the form.
        return (
            <div className="newContactMotherDiv overflow-hidden">
                <h2 className="headingInContact ml-12">Contact Me</h2>
                <h1 className="newEmail">bryantcavinta24@gmail.com</h1>
                <p className="newEmail">all lower case :)</p>
                <p className="newContactOr">Or</p>
                <form className="newContactForm" onSubmit={handleSubmit}>
                    <div className="thirdSpotContact">
                        <h1 className="afterResponseOfForm">
                            Thank you! I'll get back to you soon!
                        </h1>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <div className="newContactMotherDiv overflow-hidden">
            <h2 className="headingInContact ml-12">Contact</h2>
            <h1 className="newEmail">bryantcavinta24@gmail.com</h1>
            <p className="newEmail mt-10 text-center">all lower case :)</p>

            <p className="newContactOr">Or</p>
            <form className="newContactForm" onSubmit={handleSubmit}>
                <div className="firstSpotContact">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="newContactEmail"
                        placeholder="Email"
                        required
                        style={{ fontSize: "18px" }}
                    />
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                    />
                </div>

                <div className="secondSpotContact">
                    <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="organization"
                        className="newContactEmail"
                        placeholder="Company/Industry"
                    />
                    <ValidationError
                        prefix="Company"
                        field="company"
                        errors={state.errors}
                    />
                </div>

                <div className="thirdSpotContact">
                    <input
                        type="text"
                        name="message"
                        id="message"
                        className="newContactEmail1"
                        placeholder="Your Message"
                        required
                    />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                </div>

                <div className="fourthSpotContact">
                    <button
                        type="submit"
                        className="newContactEmail2 block w-full rounded-md bg-slate-700 px-3.5 py-2.5 text-center font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        disabled={state.submitting}
                    >
                        <p className="submitButton">Submit</p>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default NewContact;
