import { React, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Projects from "./Projects";
import Loading from "./Loading";
import Navbar from "./Navbar";
import NewHome from "../newcomponents/NewHome";
import NewNav from "../newcomponents/NewNav";
import NewAboutMe from "../newcomponents/NewAboutMe";
import NewContact from "../newcomponents/NewContact";
import NewProjects from "../newcomponents/NewProjects";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence>
            <NewNav />
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<NewHome />} />
                <Route path="/About" element={<About />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Projects" element={<Projects />} />
                {/* //new portfolio routes */}
                <Route path="/newHome" element={<NewHome />}></Route>
                <Route path="/newAboutMe" element={<NewAboutMe />} />
                <Route path="/newContact" element={<NewContact />} />
                <Route path="/newProjects" element={<NewProjects />} />
            </Routes>
        </AnimatePresence>
    );
}
export default AnimatedRoutes;
