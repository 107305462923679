import React from "react";
import { SocialIcon } from "react-social-icons";
function TestProject() {
    return (
        <div className="2xl:mx-auto 2xl:container z-0">
            <div className="lg:px-20 md:px-6 px-4 md:py-12 py-8">
                <h1 className="lg:text-4xl text-3xl font-semibold  text-center text-white md:my-10">
                    Here are some of my projects!
                </h1>
                <div className="flex justify-center">
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 lg:mt-16 md:mt-12 mt-8 items-center">
                        <div className="relative flex flex-col hover:scale-105 transition-all duration-200 rounded-md z-0 ">
                            <img
                                src="./images/flightbooking.jpg"
                                className="w-full"
                            />
                            <img
                                src="https://i.ibb.co/Tb5CKHn/Rectangle-49.png"
                                alt="opacity bg"
                                className="absolute w-full bottom-0"
                            />
                            <div className="absolute ml-6 bottom-24 z-30">
                                <p className="text-sm leading-none text-white">
                                    JADE Programming (Group Project)
                                </p>
                                <h1 className="w-64 text-2xl font-semibold leading-8 mt-2 text-white">
                                    Flight Booking System
                                </h1>
                            </div>
                            <div className="absolute ml-6 bottom-10 z-30">
                                <a href="Final Jade Project.zip" download>
                                    <img
                                        className="hover:scale-30 scale-10 h-10 w-10 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
                                        src="./images/icons/downloadIcon.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="relative flex flex-col hover:scale-105 transition-all duration-200 rounded-md mt-20">
                            <img
                                src="./images/icons/arduino (1).jpg"
                                className="w-full"
                            />
                            <img
                                src="https://i.ibb.co/Tb5CKHn/Rectangle-49.png"
                                alt="opacity bg"
                                className="absolute w-full bottom-0"
                            />
                            <div className="absolute ml-6 bottom-24 z-30">
                                <p className="text-sm leading-none text-white">
                                    C++, Python & React
                                </p>
                                <h1 className="w-64 text-2xl font-semibold leading-8 mt-2 text-white">
                                    Website and LED that can listen to sound
                                    (Arduino Project)
                                </h1>
                            </div>
                            <div className="absolute ml-6 bottom-10 z-30"></div>
                        </div>

                        <div className="relative flex flex-col hover:scale-105 transition-all duration-200 rounded-md">
                            <img
                                src="./images/unitygame.png"
                                className="w-full"
                            />
                            <img
                                src="https://i.ibb.co/Tb5CKHn/Rectangle-49.png"
                                alt="opacity bg"
                                className="absolute w-full bottom-0"
                            />
                            <div className="absolute ml-6 bottom-24 z-30">
                                <p className="text-sm leading-none text-white">
                                    Unity (Group Project)
                                </p>
                                <h1 className="w-64 text-2xl font-semibold leading-8 mt-2 text-white">
                                    2D Platformer Game
                                </h1>
                            </div>
                            <div className="absolute ml-6 bottom-10 z-30">
                                <SocialIcon
                                    className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
                                    url="https://www.github.com/"
                                    href="https://github.com/iAmJiro/GameProject"
                                    rel="noopener noreferrer"
                                    bgColor="black"
                                />
                            </div>
                        </div>
                        <div className="relative flex flex-col hover:scale-105 transition-all duration-200 rounded-md mt-28">
                            <img
                                src="./images/clintsever.png"
                                className="w-full"
                            />
                            <img
                                src="https://i.ibb.co/Tb5CKHn/Rectangle-49.png"
                                alt="opacity bg"
                                className="absolute w-full bottom-0 mt-5"
                            />
                            <div className="absolute ml-6 bottom-24 z-30">
                                <p className="text-sm leading-none text-white">
                                    C#
                                </p>
                                <h1 className="w-64 text-2xl font-semibold leading-8 mt-2 text-white">
                                    Client Back-to-Back Communication with
                                    Server
                                </h1>
                            </div>
                            <div className="absolute ml-6 bottom-10 z-30">
                                <SocialIcon
                                    className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
                                    url="https://www.github.com/"
                                    href="https://github.com/iAmJiro/Server"
                                    rel="noopener noreferrer"
                                    bgColor="black"
                                />
                            </div>
                        </div>
                        <div className="relative flex flex-col hover:scale-105 transition-all duration-200 rounded-md mt-20">
                            <img
                                src="./images/easyday.png"
                                className="w-full"
                            />
                            <img
                                src="https://i.ibb.co/Tb5CKHn/Rectangle-49.png"
                                alt="opacity bg"
                                className="absolute w-full bottom-0"
                            />
                            <div className="absolute ml-6 bottom-24 z-30">
                                <p className="text-sm leading-none text-white">
                                    ASP.NET & REACT w/ Tailwind (Group Project)
                                </p>
                                <h1 className="w-64 text-2xl font-semibold leading-8 mt-2 text-white">
                                    E-Commerce Merch Store
                                </h1>
                            </div>
                            <div className="absolute ml-6 bottom-10 z-30">
                                <SocialIcon
                                    className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
                                    url="https://www.github.com/"
                                    href="https://github.com/iAmJiro/Software-Eng"
                                    rel="noopener noreferrer"
                                    bgColor="black"
                                />
                            </div>
                        </div>
                        <div className="relative flex flex-col hover:scale-105 transition-all duration-200 rounded-md mt-20">
                            <img
                                src="./images/cake_resized.png"
                                className="w-full"
                            />
                            <img
                                src="https://i.ibb.co/Tb5CKHn/Rectangle-49.png"
                                alt="opacity bg"
                                className="absolute w-full bottom-0"
                            />
                            <div className="absolute ml-6 bottom-24 z-30">
                                <p className="text-sm leading-none text-white">
                                    ASP.NET & REACT w/ Tailwind (Group Project)
                                </p>
                                <h1 className="w-64 text-2xl font-semibold leading-8 mt-2 text-white">
                                    Cake Making Website
                                </h1>
                            </div>
                            <div className="absolute ml-6 bottom-10 z-30">
                                <SocialIcon
                                    className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
                                    url="https://www.github.com/"
                                    href="https://github.com/iAmJiro/ProjectBDNS"
                                    rel="noopener noreferrer"
                                    bgColor="black"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TestProject;
