import { React, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Loading from "./Loading";
import AboutAnimText from "./AboutTextAnim";
import Url from "./Url";
import Aboutpagetop from "./Aboutpagetop";
import Skills from "./Skills";
function About() {
    // const [loading, setLoading] = useState(true);
    // useEffect(() => {
    //     setTimeout(() => setLoading(false), 3300);
    // }, []);
    // if (loading) {
    //     return <Loading />;
    // }
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    function handleButtonClick() {
        setIsButtonDisabled(true);
    }

    <link rel="stylesheet" href="output.css" />;
    const words = [
        "Who is bryant? hmmmm",
        "Bachelor of Information Technology",
        "bottom text goes here",
    ];
    const colors = ["black"];
    return (
        <motion.div
            className="motherdivabout"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {/* <Url /> */}
            <div className="containerfirstabout">
                <div className="grid1about-first">
                    <h3 className="Abouttext">About</h3>
                </div>
                <div className="grid2about-first">
                    <h1 className="letterB">B</h1>
                    <h1 className="letterr">r</h1>
                    <h1 className="lettery">y</h1>
                    <h1 className="lettera">a</h1>
                    <h1 className="lettern">n</h1>
                    <h1 className="lettert">t</h1>
                </div>
                <div className="grid3about-first">
                    <h3 className="cavintatext"> cavinta</h3>
                </div>
            </div>
            <div className="containersecondabout">
                <div className="searchbarbox">
                    <div className="searchbar">
                        <div>
                            <AboutAnimText words={words} colors={colors} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="containerthirdabout">
                <div className="skillsbuttondiv scroll-smooth">
                    <a href="#Skills">
                        <h1>Skills</h1>
                    </a>
                </div>
                <div className="hobbiesbuttondiv">
                    <a href="#Hobbies">
                        <h1>Hobbies</h1>
                    </a>
                </div>
                <div className="cvbuttondiv">
                    <a href="Bryant Resume.pdf">
                        <h1>CV Download</h1>
                    </a>
                </div>
                <div className="whobuttondiv">
                    <a href="#Who">
                        <h1>Who am I?</h1>
                    </a>
                </div>
            </div>
            <div className="containerfourthabout"></div>
            <div className="containerfifthabout">
                <section id="Skills" className="Aboutskillspage mb-10">
                    <div className="skillsside">
                        <div className="grid1-fifthabout">
                            <Aboutpagetop text="Skills" />
                        </div>
                        <div className="skillsbargraph mt-10">
                            <Skills />
                        </div>
                        <div className="skillstext md:mr-5">
                            <h1 className="ml-10 text-md md:text-2xl mt-10">
                                Soft Skills
                            </h1>
                            <p className="mx-10 mt-2 text-sm md:text-xl">
                                I have a keen interest in leadership, in the
                                duration of my study, I have been the project
                                manager in multiple group projects, I enjoy
                                being able to lead my teammates and succeeding
                                with them.
                            </p>
                            <p className="mx-10 mt-2 text-sm md:text-xl">
                                I also am a team member too, I have experienced
                                both being the leader and being led. I know how
                                to work in a team setting.
                            </p>
                            <p className="mx-10 mt-2 text-sm md:text-xl">
                                Public speaking is something I am proud that I
                                can do, I enjoy presenting for my peers.
                            </p>
                            <p className="mx-10 mt-2 text-sm md:text-xl">
                                Time Management, I have always been in time with
                                my submissions, with a reasonable time frame, I
                                can finish my work.
                            </p>
                        </div>
                        <br />
                        <div className="skillsfunfact md:mr-5">
                            <h1 className="text-center md:ml-20 text-md md:text-2xl mt-10">
                                FUN FACT! I can read
                            </h1>
                        </div>
                    </div>
                </section>
                <section id="Hobbies" className="Abouthobbiespage mt-4">
                    <div>
                        <Aboutpagetop text="Hobbies" />
                    </div>
                    <h1 className="ml-10 mt-3 text-md md:text-4xl md:text-center">
                        Art
                    </h1>
                    <p className="mx-10 mt-2 text-sm md:text-2xl md:text-center">
                        I enjoy art! I practice medias such as painting, drawing
                        and photograpy. Below are some of my work.
                    </p>
                    <p className="mx-10 mt-2 text-sm md:text-2xl md:text-center">
                        Here are is an example of a traditional painting and a
                        digital painting that I did!
                    </p>
                    <div className="paintingcontainer">
                        <div className="firstpainting mt-2 flex flex-col items-center">
                            <img
                                className="rounded h-auto w-80 md:w-96 shadow-sm"
                                src="./images/mountaintjap.png"
                                alt=""
                            />
                            <p className="text-sm text-center md:text-base">
                                A Japanese inspired art style (2021)
                            </p>
                        </div>
                        <div className="secondpainting mt-2 flex flex-col items-center">
                            <img
                                className="rounded h-auto w-80 md:w-96 shadow-sm"
                                src="./images/IMG_0735 (1).jpeg"
                                alt=""
                            />
                            <p className="text-sm text-center md:text-base">
                                Clouds reflecting on still water (2024)
                            </p>
                        </div>
                        <div className="thirdpainting mt-2 flex flex-col items-center">
                            <img
                                className="rounded h-auto w-80 md:w-96 shadow-sm"
                                src="./images/catand bird.png"
                                alt=""
                            />
                            <p className="text-sm text-center md:text-base">
                                A big world ahead (2020)
                            </p>
                        </div>
                    </div>
                    <div className="bottomart">
                        <div className="musicdiv">
                            <h1 className="ml-10 mt-3 text-md md:text-3xl md:text-center md:ml-0">
                                Music
                            </h1>
                            <p className="mx-10 mt-2 text-sm md:mx-12">
                                I played a variety of musical instruments
                                growing up, these include the drums and the
                                piano. Recently I have been playing more ukelele
                                than the rest of the others.
                            </p>
                            <div className="videoukelele mt-2 flex flex-col items-center">
                                <video
                                    className=" rounded h-72 w-auto"
                                    controls
                                    src="./images/IMG_0795.mov"
                                ></video>
                            </div>
                            <p className="mx-10 mt-2 text-sm text-center">
                                Here is a video of my playing the ukelele! Visit
                                my instagram highlights for more videos!
                            </p>
                        </div>
                        <div className="videogamediv">
                            <h1 className="ml-10 mt-3 text-md md:text-3xl md:text-center md:ml-0">
                                Video Games
                            </h1>
                            <p className="mx-10 mt-2 text-sm">
                                I love playing video games, especially with
                                friends. It is a nice past time activity for me
                                to do! Some games I play are, League of Legends
                                and Valorant.
                            </p>
                            <div className="videocontent mt-2 flex flex-col items-center">
                                <video
                                    className=" rounded h-72 w-auto"
                                    controls
                                    src="./images/content.mp4"
                                ></video>
                            </div>
                            <p className="mx-10 mt-2 text-sm text-center">
                                Here is a video of my playing Content Warning
                                with my friend!
                            </p>
                        </div>
                        <div className="dancediv">
                            <h1 className="ml-10 mt-3 text-md md:text-3xl md:text-center md:ml-0">
                                Dance
                            </h1>
                            <p className="mx-10 mt-2 text-sm">
                                I love dancing! Recently I have been practicing
                                the style "Krump" but I mostly do hip-hop style
                                with popping added into it. I have also added
                                "Tutting" to style of dances I do!
                            </p>
                            <div className="videocontent mt-2 flex flex-col items-center">
                                <video
                                    className=" rounded h-72 w-auto"
                                    controls
                                    src="./images/dance.mp4"
                                ></video>
                            </div>
                            <p className="mx-10 mt-2 text-sm text-center">
                                For my dance videos, visit my instagram
                                highlights!
                            </p>
                        </div>
                        <div className="sportsdiv">
                            <h1 className="ml-10 mt-3 text-md md:text-3xl md:text-center md:ml-0">
                                Sports
                            </h1>
                            <p className="mx-10 mt-2 text-sm mb-10">
                                I play basketball and volleyball and if it
                                counts, I go to the gym! I would also always be
                                keen to participating in any other sports!
                            </p>
                            <div className="mt-2 flex flex-col items-center">
                                <img
                                    className="rounded h-60 w-80"
                                    src="./images/kobe.jpg"
                                    alt=""
                                />
                            </div>

                            <p className="mx-10 mt-2 text-sm text-center">
                                Here is a picture of the goat basketball player
                                Kobe
                            </p>
                        </div>
                    </div>
                    <br />

                    <div className="skillsfunfact md:mr-5">
                        <h1 className="text-center md:ml-20 text-md md:text-2xl mt-10">
                            FUN FACT! I can juggle and beatbox B)
                        </h1>
                    </div>
                </section>
                <section id="Who" className="Aboutwhoamipage md:mt-20">
                    <div>
                        <Aboutpagetop text="Who am I?" />
                    </div>
                    <div className="childhoodandhappening mt-10">
                        <div className="childhooddiv">
                            <h1 className="ml-10 md:ml-10 mt-3 text-md text-md md:text-3xl md:ml-0">
                                Childhood
                            </h1>
                            <p className="mx-10 mt-2 text-sm md:text-base">
                                I grew up in the northern side of Philippines!
                                Luzon! I spent my first 11 years of life in the
                                Philippines and after that we moved to New
                                Zealand.
                            </p>
                            <p className="mx-10 mt-2 text-sm md:text-base">
                                I enjoyed my childhood! I had lots of cool
                                memories and I still talk to some of my
                                childhood friends which is a bonus.
                            </p>
                        </div>
                        <div className="happeningdiv">
                            <h1 className="ml-10 md:ml-10 mt-3 text-md text-md md:text-3xl md:ml-0">
                                What is happening now?
                            </h1>
                            <p className="mx-10 mt-2 text-sm md:text-base">
                                I am now 21 years old, 22 in June 24! I am on my
                                final year of Information Technology here in
                                Southern Institute of Technology, Invercargill,
                                New Zealand.
                            </p>
                            <p className="mx-10 mt-2 text-sm md:text-base">
                                I am really loving Web Development as of now, I
                                went into the degree in hopes of learning how to
                                make a game! After that I really got into
                                database development and thought that was how
                                I'll start my career. And after all of that, I
                                decided that Web development is the one for me
                                since I can express art with it! Which I love!
                            </p>
                        </div>
                    </div>
                </section>
                <h1 className="text-center md:ml-20 text-md md:text-2xl mt-10">
                    And that is me! You now know who I am :)
                </h1>
            </div>
        </motion.div>
    );
}
export default About;
