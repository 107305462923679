import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function NewNav() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isOpen]);

    return (
        <div className="newNav flex justify-between items-center px-4 py-2 relative">
            <Link to="/newHome" onClick={closeMenu}>
                <img
                    className="newLogo w-10 h-10"
                    src={`${process.env.PUBLIC_URL}/images/newlogo.png`}
                    alt="Logo"
                />
            </Link>

            <ul className="newHomeLink my-4 md:my-0">
                <li className="navigations transition-all duration-300 hover:ml-2">
                    <Link to="/newHome" onClick={closeMenu}>
                        .Home()
                    </Link>
                </li>
            </ul>
            <ul className="newAboutLink my-4 md:my-0">
                <li className="navigations transition-all duration-300 hover:ml-2">
                    <Link to="/newAboutMe" onClick={closeMenu}>
                        .About()
                    </Link>
                </li>
            </ul>
            <ul className="newContactLink my-4 md:my-0">
                <li className="navigations transition-all duration-300 hover:ml-2">
                    <Link to="/newContact" onClick={closeMenu}>
                        .Contact()
                    </Link>
                </li>
            </ul>
            <ul className="newProjectsLink my-4 md:my-0">
                <li className="navigations transition-all duration-300 hover:ml-2">
                    <Link to="/newProjects" onClick={closeMenu}>
                        .Projects()
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default NewNav;
