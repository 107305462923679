import React from "react";
import "./Home.css";
function Aboutpagetop(props) {
    return (
        <div>
            <div className="searchbarsections">
                <div className="grid2about-firstsections float-start">
                    <h1 className="letterB text-3xl md:text-6xl">B</h1>
                    <h1 className="letterr text-3xl md:text-6xl">r</h1>
                    <h1 className="lettery text-3xl md:text-6xl">y</h1>
                    <h1 className="lettera text-3xl md:text-6xl">a</h1>
                    <h1 className="lettern text-3xl md:text-6xl">n</h1>
                    <h1 className="lettert text-3xl md:text-6xl">t</h1>
                </div>
                <div className="searchbarabout md:text-2xl md:ml-2">
                    <div className="ml-2">{props.text}</div>
                </div>
            </div>
            <div className="searchresults">
                <div className="containerresults">
                    <h3 className="aboutresults opacity-70 text-sm ml-10 md:text-2xl">
                        {" "}
                        About 24,062,002 results (0.21seconds)
                    </h3>
                </div>
                <div className="searchedresults ml-10 md:text-3xl">
                    <h2 className="thesearcheditems">Your Searched Items</h2>
                </div>
            </div>
        </div>
    );
}
export default Aboutpagetop;
