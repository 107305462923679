import React from "react";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ConsoleText from "../components/ConsoleText";
import "./newport.css";
function NewHome() {
    <link rel="stylesheet" href="output.css" />;
    const words = ["Programmer", "Artist", "Performer"];
    const colors = ["tomato", "purple", "green"];
    const fadeInUp = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    const hoverScale = {
        hover: { scale: 1.1, transition: { duration: 0.3 } },
    };
    return (
        <div className="newHomeMotherDiv ">
            <motion.div
                className="newHomeGrid1"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                <h2 className="nameInHome ml-4">Bryant's</h2>
            </motion.div>

            <motion.p
                className="newInHomeText"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                hello, I’M Bryant, I’m A Full Stacker Developer
            </motion.p>

            <motion.div
                className="portfolioInHomeFirst ml-10"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                <h1 className="portftext ml-10">PORTF</h1>
            </motion.div>

            <motion.img
                className="newPortPic1"
                src={`${process.env.PUBLIC_URL}/images/newportpic1.png`}
                alt="Avatar"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            />

            <motion.div
                className="portfolioInHomeSecond"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                <h1 className="liotext">LIO</h1>
            </motion.div>

            <motion.div
                className="newAboutMeButton text-3xl text-center mx-2"
                whileHover="hover"
                variants={hoverScale}
            >
                <Link
                    to="/newAboutMe"
                    className="transform hover:scale-110 focus:outline-none transition duration-150 ease-in-out"
                >
                    About Me
                </Link>
            </motion.div>

            <motion.div
                className="newCVButton text-3xl text-center mx-2"
                whileHover="hover"
                variants={hoverScale}
            >
                <a
                    href="Bryant Resume.pdf"
                    download
                    className="transform hover:scale-110 focus:outline-none transition duration-150 ease-in-out"
                >
                    <button>CV</button>
                </a>
            </motion.div>

            <motion.div
                className="newProjectsButton text-3xl text-center mx-2"
                whileHover="hover"
                variants={hoverScale}
            >
                <Link
                    to="/newProjects"
                    className="transform hover:scale-110 focus:outline-none transition duration-150 ease-in-out"
                >
                    My Projects
                </Link>
            </motion.div>
            <SocialIcon
                className="instagramicon transform hover:scale-110 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2"
                url="www.linkedin.com/in/bryant-cavinta-68424a25b"
                rel="noopener noreferrer"
                fgColor="black"
                bgColor="white"
            />
            <SocialIcon
                className="facebookicon transform hover:scale-110 scale-90 focus:outline-none transition duration-150 ease-in-out mx-4"
                url="https://www.facebook.com/bryantjei.cavinta.7/"
                rel="noopener noreferrer"
                fgColor="black"
                bgColor="white"
            />
            <SocialIcon
                className="githubicon transform hover:scale-110 scale-90 focus:outline-none transition duration-150 ease-in-out mx-4"
                url="https://github.com/iAmJiro"
                rel="noopener noreferrer"
                fgColor="black"
                bgColor="white"
            />
        </div>
    );
}
export default NewHome;
