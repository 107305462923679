import React from "react";
import ConsoleText from "../components/ConsoleText";
import { motion } from "framer-motion";
function NewAboutMe() {
    <link rel="stylesheet" href="output.css" />;
    const words = ["I am a Programmer", "I am an Artist", "I am a Performer"];
    const colors = ["white"];
    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };
    return (
        <div className="newAboutMeMothderDiv">
            <div className="newAboutMeFirstPage">
                <motion.p
                    className="newAboutFirstText"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                >
                    Hey there! I love creating! I had grown up in a household
                    where we painted, drew, and designed! I have entered
                    multiple art competitions growing up!
                </motion.p>

                <motion.p
                    className="newAboutSecondText"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                >
                    My path to pursuing a career in IT has been enjoyable so
                    far. I had explored game development and database
                    development before I settled to really polish my skills in
                    full-stack development.
                </motion.p>
                <img
                    className="dogpeeking"
                    src={`${process.env.PUBLIC_URL}/images/dogpeeking.png`}
                    alt="Avatar"
                />
                <div className="newJSText">
                    <ConsoleText words={words} colors={colors} />
                </div>
                <img
                    className="catpeeking"
                    src={`${process.env.PUBLIC_URL}/images/catpeeking.png`}
                    alt="Avatar"
                />
            </div>
            <div className="newAboutMeSecondPage">
                <div className="newLeftSideSecondPage">
                    <h1 className="myLeftSkillsHeading"> My Tech Skills</h1>
                    <div className="leftSkillTab"></div>
                    <div className="leftSideReactDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideReact text-3xl text-center ">
                            React
                        </h2>
                    </div>
                    <div className="leftSideJSDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideJS text-3xl text-center ">
                            JavaScript
                        </h2>
                    </div>
                    <div className="leftSideCSSDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideCSS text-3xl text-center ">
                            CSS
                        </h2>
                    </div>
                    <div className="leftSideDesignDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideDesign text-3xl text-center ">
                            Design
                        </h2>
                    </div>
                    <div className="leftSideASPDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideASP text-3xl text-center ">
                            ASP.NET Core
                        </h2>
                    </div>
                    <div className="leftSideTSDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideTS text-3xl text-center ">
                            TypeScript
                        </h2>
                    </div>
                    <div className="leftSideHTMLDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideHTML text-3xl text-center ">
                            HTML
                        </h2>
                    </div>
                    <div className="leftSideTWDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideTW text-3xl text-center ">
                            Tailwind
                        </h2>
                    </div>
                    <div className="leftSideKRDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideKR text-3xl text-center ">
                            Krita
                        </h2>
                    </div>
                    <div className="leftSidePYDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSidePY text-3xl text-center ">
                            Python
                        </h2>
                    </div>
                    <div className="leftSideSDLCDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideSDLC text-3xl text-center ">
                            SDLC
                        </h2>
                    </div>
                    <div className="leftSideCDiv transform hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mx-2">
                        <h2 className="leftSideC text-3xl text-center ">C#</h2>
                    </div>
                </div>
                <div className="newRightSideSecondPage overflow-x-hidden">
                    <div className="rightSideTab"></div>
                    <h1 className="myRightSkillsHeading">My Soft Skills</h1>
                    <div className="topLeftSoftSkill">
                        <h1 className="softSkillHeadings">Communication</h1>
                        <p className="softSkillText">
                            I loved performance arts,
                        </p>
                        <p className="softSkillText">
                            and naturally I developed a
                        </p>
                        <p className="softSkillText">
                            good sense of public talking.
                        </p>
                        <p className="softSkillText">I am a peoples person!</p>
                    </div>
                    <div className="topRightSoftSkill">
                        <h1 className="softSkillHeadings">Time Management</h1>
                        <p className="softSkillText">
                            Through my education and
                        </p>
                        <p className="softSkillText">
                            outside activities, the way
                        </p>
                        <p className="softSkillText">
                            I manage my time is some thing
                        </p>
                        <p className="softSkillText">
                            I'm proud of. I don't miss deadlines!
                        </p>
                    </div>
                    <div className="bottomLeftSoftSkill">
                        <h1 className="softSkillHeadings">Teamwork</h1>
                        <p className="softSkillText">
                            As much as I am independent,
                        </p>
                        <p className="softSkillText">
                            I love to work in teams!
                        </p>
                        <p className="softSkillText">
                            I have participated in multiple
                        </p>
                        <p className="softSkillText">
                            projects during my studies as a
                        </p>
                        <p className="softSkillText">
                            project manager and a team member!
                        </p>
                    </div>
                    <div className="bottomRightSoftSkill">
                        <h1 className="softSkillHeadings">EQ</h1>
                        <p className="softSkillText">
                            I know where the line is!
                        </p>
                        <p className="softSkillText">
                            I have great social skills
                        </p>
                        <p className="softSkillText">Strong motivation</p>
                        <p className="softSkillText">and big empathy!</p>
                    </div>
                </div>
                <div className="newAboutMeThirdPage overflow-x-hidden overflow-y-hidden">
                    <h1 className="newThirdPageHeader">
                        Spare time activities? I have some!
                    </h1>
                    <img
                        className="hoverarrow"
                        src={`${process.env.PUBLIC_URL}/images/hovermerarrow.png`}
                        alt="Avatar"
                    />
                    <div className="newFirstActivity">
                        <h1 className="newTextActivities1">Arts</h1>
                        <p className="newPActivities1 mt-5">
                            i like to paint and dance! I do digital and
                            traditional art and hip-hop for dance!{" "}
                        </p>
                        <img
                            className="newPAImage1"
                            src={`${process.env.PUBLIC_URL}/images/mountaintjap.png`}
                            alt=""
                        />
                        <img
                            className="newPAImage2"
                            src={`${process.env.PUBLIC_URL}/images/catandbird.png`}
                            alt=""
                        />
                        <img
                            className="newPAImage3"
                            src={`${process.env.PUBLIC_URL}/images/lakesky.jpeg`}
                            alt=""
                        />
                        <img
                            className="newPAImage4"
                            src={`${process.env.PUBLIC_URL}/images/lounge.png`}
                            alt=""
                        />
                    </div>
                    <div className="newSecondActivity">
                        <h1 className="newTextActivities2">Sports</h1>
                        <p className="newPActivities2 mt-5">
                            I play basketball and volleyball! I also like to
                            keep fit through calisthenics{" "}
                        </p>
                        <p className="newPActivities22">
                            While I'm not very good at other sports, I like
                            participating in them!
                        </p>
                        <img
                            className="newPAImage5"
                            src={`${process.env.PUBLIC_URL}/images/beavertt.png`}
                            alt=""
                        />
                    </div>
                    <div className="newThirdActivity">
                        <h1 className="newTextActivities3">Music</h1>
                        <p className="newPActivities3 mt-5 mr-2">
                            I love music! I have played the drums, piano and the
                            ukelele!
                        </p>
                        <div className="videoukelele mt-2 flex flex-col items-center">
                            <video
                                className="rounded h-72 w-auto"
                                controls
                                src="./images/IMG_0795.mov"
                            ></video>
                        </div>
                    </div>
                    <div className="newFourthActivity">
                        <h1 className="newTextActivities4">Games</h1>
                        <p className="newPActivities4 mt-5 mr-2">
                            I enjoy playing video games and tabletop games!
                            Friends make it better!{" "}
                        </p>
                        <p className="newPActivities44 mt-5 mr-2">
                            I like to play League of Legends for video games and
                            chess for board games. Ofcourse I like other things
                            too!{" "}
                        </p>
                        <img
                            className="newPAImage6"
                            src={`${process.env.PUBLIC_URL}/images/leb.png`}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NewAboutMe;
