import React from "react";

import Navbar from "./components/Navbar";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Social from "./components/Social";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { useEffect, useState } from "react";
import Loading from "./components/Loading";
import NewNav from "./newcomponents/NewNav";
function App() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => setLoading(false), 3300);
    }, []);
    // if (loading) {
    //     return <Loading />;
    // }
    return (
        <div>
            <div className="">
                <Router basename="/" Router>
                    {/* <Navbar /> */}
                    <AnimatedRoutes />
                    {/* <Social /> */}
                </Router>
            </div>
        </div>
    );
}

export default App;
