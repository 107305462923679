import { React, useState, useEffect } from "react";
import Form from "./Form";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";
function Contact() {
    return (
        <motion.div
            className="contactmotherdiv"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 1 }}
        >
            <div className="containerfirstContact">
                <div className="contactgrid1">
                    <img src="./images/for_bryant_edited4.png" alt="" />
                </div>
            </div>
            <div className="containerthirdContact">
                <Form />
            </div>
        </motion.div>
    );
}
export default Contact;
