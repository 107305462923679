import { React, useState, useEffect } from "react";
import "./Home.css";
import ConsoleText from "./ConsoleText";
import { motion } from "framer-motion";
import Loading from "./Loading";
function Home() {
    <link rel="stylesheet" href="output.css" />;
    const words = ["Programmer", "Artist", "Performer"];
    const colors = ["tomato", "purple", "green"];

    return (
        <motion.div
            className="motherdiv"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 1 }}
        >
            <div className="containerhome">
                <div className="grid1">
                    <h1 className="name">BRYANT CAVINTA</h1>
                    <div class="console-container">
                        <span id="text"></span>
                        <div className="textundername">
                            <ConsoleText words={words} colors={colors} />
                        </div>
                    </div>
                </div>
                <div className="grid4">
                    <h2 className="leftText">
                        Full stack developer and a UI/UX designer that's
                        passionate about creating functional user experiences
                    </h2>
                </div>
                <div className="grid5">
                    <img
                        className="pict"
                        src={`${process.env.PUBLIC_URL}/images/finalpicture.png`}
                        alt=""
                    />
                </div>

                <div className="grid6">
                    <a href="Bryant Resume.pdf" download>
                        <button>CV Download</button>
                    </a>
                </div>
            </div>
        </motion.div>
    );
}
export default Home;
