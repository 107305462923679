import React from "react";
function Skills() {
    return (
        <div className="skillsboxes mx-10">
            <div class="skillsdiv h-80 flex justify-center items-center">
                <div class="max-w-xl mx-auto w-full">
                    <h4 class="text-md md:text-2xl dark:text-black mb-6 md:mt-10">
                        My skills around programming and other apps/ softwares
                    </h4>

                    <div class="mb-3">
                        <div class="flex justify-between py-1">
                            <span class="text-base text-gray-lite font-semibold dark:text-[#000000]">
                                Web Application
                            </span>
                            <span class="text-base font-semibold text-gray-lite pr-5 dark:text-[#000000]">
                                80%
                            </span>
                        </div>
                        <svg
                            class="rc-progress-line"
                            viewBox="0 0 100 1"
                            preserveAspectRatio="none"
                        >
                            <path
                                class="rc-progress-line-trail"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#D9D9D9"
                                stroke-width="1"
                                fill-opacity="0"
                            ></path>
                            <path
                                class="rc-progress-line-path"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#FF6464"
                                stroke-width="1"
                                fill-opacity="0"
                                style={{
                                    strokeDasharray: "80px, 100px",
                                    strokeDashoffset: "0px",
                                    transition:
                                        "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s linear 0s, 0.06s",
                                }}
                            ></path>
                        </svg>
                    </div>

                    <div class="mb-3">
                        <div class="flex justify-between py-1">
                            <span class="text-base text-gray-lite font-semibold dark:text-[#000000]">
                                Mobile App{" "}
                            </span>
                            <span class="text-base font-semibold text-gray-lite pr-5 dark:text-[#000000]">
                                70%
                            </span>
                        </div>
                        <svg
                            class="rc-progress-line"
                            viewBox="0 0 100 1"
                            preserveAspectRatio="none"
                        >
                            <path
                                class="rc-progress-line-trail"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#D9D9D9"
                                stroke-width="1"
                                fill-opacity="0"
                            ></path>
                            <path
                                class="rc-progress-line-path"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#9272D4"
                                stroke-width="1"
                                fill-opacity="0"
                                style={{
                                    strokeDasharray: "70px, 100px",
                                    strokeDashoffset: "0px",
                                    transition:
                                        "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s linear 0s, 0.06s",
                                }}
                            ></path>
                        </svg>
                    </div>

                    <div class="mb-3">
                        <div class="flex justify-between py-1">
                            <span class="text-base text-gray-lite font-semibold dark:text-[#000000]">
                                OOP
                            </span>
                            <span class="text-base font-semibold text-gray-lite pr-5 dark:text-[#000000]">
                                70%
                            </span>
                        </div>
                        <svg
                            class="rc-progress-line"
                            viewBox="0 0 100 1"
                            preserveAspectRatio="none"
                        >
                            <path
                                class="rc-progress-line-trail"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#D9D9D9"
                                stroke-width="1"
                                fill-opacity="0"
                            ></path>
                            <path
                                class="rc-progress-line-path"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#5185D4"
                                stroke-width="1"
                                fill-opacity="0"
                                style={{
                                    strokeDasharray: "70px, 100px",
                                    strokeDashoffset: "0px",
                                    transition:
                                        "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s linear 0s, 0.06s",
                                }}
                            ></path>
                        </svg>
                    </div>

                    <div class="mb-3">
                        <div class="flex justify-between py-1">
                            <span class="text-base text-gray-lite font-semibold dark:text-[#000000]">
                                Database Development
                            </span>
                            <span class="text-base font-semibold text-gray-lite pr-5 dark:text-[#000000]">
                                50%
                            </span>
                        </div>
                        <svg
                            class="rc-progress-line"
                            viewBox="0 0 100 1"
                            preserveAspectRatio="none"
                        >
                            <path
                                class="rc-progress-line-trail"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#D9D9D9"
                                stroke-width="1"
                                fill-opacity="0"
                            ></path>
                            <path
                                class="rc-progress-line-path"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#CA56F2"
                                stroke-width="1"
                                fill-opacity="0"
                                style={{
                                    strokeDasharray: "50, 100px",
                                    strokeDashoffset: "0px",
                                    transition:
                                        "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s linear 0s, 0.06s",
                                }}
                            ></path>
                        </svg>
                    </div>
                    <div class="mb-3">
                        <div class="flex justify-between py-1">
                            <span class="text-base text-gray-lite font-semibold dark:text-[#000000]">
                                Art Softwares
                            </span>
                            <span class="text-base font-semibold text-gray-lite pr-5 dark:text-[#000000]">
                                80%
                            </span>
                        </div>
                        <svg
                            class="rc-progress-line"
                            viewBox="0 0 100 1"
                            preserveAspectRatio="none"
                        >
                            <path
                                class="rc-progress-line-trail"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#D9D9D9"
                                stroke-width="1"
                                fill-opacity="0"
                            ></path>
                            <path
                                class="rc-progress-line-path"
                                d="M 0.5,0.5
L 99.5,0.5"
                                stroke-linecap="round"
                                stroke="#FF6464"
                                stroke-width="1"
                                fill-opacity="0"
                                style={{
                                    strokeDasharray: "80px, 100px",
                                    strokeDashoffset: "0px",
                                    transition:
                                        "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s linear 0s, 0.06s",
                                }}
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Skills;
