import { React, useState, useEffect } from "react";
import { motion } from "framer-motion";
import Loading from "./Loading";
import { SocialIcon } from "react-social-icons";
import TestProject from "./TestProject";
// ui taken from uiverse by vamsidevendrakumar
function Projects() {
    return <TestProject />;
    // return (
    //     <div className="bg-white">
    //         <div className="containerfirstprojects">
    //             <div className="grid1-projects">
    //                 <h1 className="mt-5 text-xl md:text-3xl">
    //                     Here are some of my projects!
    //                 </h1>
    //             </div>
    //         </div>
    //         <div className="containersecondprojects">
    //             <div className="grid1-projects-box1">
    //                 <div class="card">
    //                     <div class="card-inner">
    //                         <div class="card-front">
    //                             <img
    //                                 className="md:h-auto md:w-72 rounded-md object-contain"
    //                                 src={`${process.env.PUBLIC_URL}/images/jade_development.png`}
    //                                 alt=""
    //                             />
    //                         </div>
    //                         <div class="card-back">
    //                             <p className="absolute">
    //                                 A Flight booking system made from Jade
    //                                 Development (Group)
    //                             </p>
    //                             <a href="Final Jade Project.zip" download>
    //                                 <img
    //                                     className="hover:scale-30 scale-10 h-10 w-10 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
    //                                     src="./images/icons/downloadIcon.png"
    //                                     alt=""
    //                                 />
    //                             </a>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="grid1-projects-box2">
    //                 <div class="card">
    //                     <div class="card-inner">
    //                         <div class="card-front">
    //                             <img
    //                                 className="h-56 w-auto rounded-md"
    //                                 src={`${process.env.PUBLIC_URL}/images/easyday.png`}
    //                                 alt=""
    //                             />
    //                         </div>
    //                         <div class="card-back">
    //                             <p className="absolute">
    //                                 A Website for a friend showcasing their
    //                                 merch (Group)
    //                             </p>
    //                             <SocialIcon
    //                                 className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
    //                                 url="https://www.github.com/"
    //                                 href="https://github.com/iAmJiro/Software-Eng"
    //                                 rel="noopener noreferrer"
    //                                 bgColor="red"
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="grid1-projects-box3">
    //                 <div class="card">
    //                     <div class="card-inner">
    //                         <div class="card-front">
    //                             <img
    //                                 className=" h-52 w-full rounded-md"
    //                                 src={`${process.env.PUBLIC_URL}/images/INTITIAL_MENU.png`}
    //                                 alt=""
    //                             />
    //                         </div>
    //                         <div class="card-back">
    //                             <p className="absolute">
    //                                 A 2D Platformer made from Unity (Group)
    //                             </p>
    //                             <SocialIcon
    //                                 className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
    //                                 url="https://www.github.com/"
    //                                 href="https://github.com/iAmJiro/GameProject"
    //                                 rel="noopener noreferrer"
    //                                 bgColor="red"
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="grid1-projects-box4">
    //                 <div class="card">
    //                     <div class="card-inner">
    //                         <div class="card-front">
    //                             <img
    //                                 src="./images/icons/clientserver.png"
    //                                 alt=""
    //                             />
    //                         </div>
    //                         <div class="card-back">
    //                             <p className="absolute">
    //                                 A server written in C# that can handle
    //                                 clients (Group)
    //                             </p>
    //                             <SocialIcon
    //                                 className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
    //                                 url="https://www.github.com/"
    //                                 href="https://github.com/iAmJiro/Server"
    //                                 rel="noopener noreferrer"
    //                                 bgColor="red"
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="grid1-projects-box5">
    //                 <div class="card">
    //                     <div class="card-inner">
    //                         <div class="card-front">
    //                             <img
    //                                 className="h-auto w-52"
    //                                 src="./images/icons/cake.png"
    //                                 alt=""
    //                             />
    //                         </div>
    //                         <div class="card-back">
    //                             <p className="absolute">
    //                                 A website for a client who loves baking
    //                                 cakes (Group)
    //                             </p>
    //                             <SocialIcon
    //                                 className=" hover:scale-100 scale-90 focus:outline-none transition duration-150 ease-in-out mt-36 mr-52"
    //                                 url="https://www.github.com/"
    //                                 href="https://github.com/iAmJiro/ProjectBDNS"
    //                                 rel="noopener noreferrer"
    //                                 bgColor="red"
    //                             />
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="grid1-projects-box6">
    //                 <div class="card">
    //                     <div class="card-inner">
    //                         <div class="card-front">
    //                             <img
    //                                 className="rounded-md"
    //                                 src="./images/icons/arduino (1).jpg"
    //                                 alt=""
    //                             />
    //                         </div>
    //                         <div class="card-back">
    //                             <p>
    //                                 An arduino project that turns on a light
    //                                 bulb with two claps
    //                             </p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
}
export default Projects;
